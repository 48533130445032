<template>
  <div class="my_info">
    <div class="header">
      <div @click="handelGoLogin()">
        <img class="header_img" src="@/assets/images/default_img.png" alt="" />
        <h5 v-if="!loginUser">
          未登录 <img src="@/assets/images/go.png" alt="" />
        </h5>
        <h5 v-if="loginUser">{{ loginUser.name }}</h5>
        <h6 v-if="loginUser">{{ loginUser.phoneNum }}</h6>
      </div>
    </div>
    <div class="menu" v-if="loginUser">
      <div class="item" @click="handelMyEvaluation()">
        <img class="icon" src="@/assets/images/book.png" alt="" />
        <span>我的测评</span>
        <img class="right_icon" src="@/assets/images/right_icon.png" alt="" />
      </div>
      <div class="item" @click="handelUserInfo()">
        <img class="icon" src="@/assets/images/people.png" alt="" />
        <span>个人信息</span>
        <img class="right_icon" src="@/assets/images/right_icon.png" alt="" />
      </div>
    </div>
    <div class="menu" v-if="loginUser">
      <div class="item" @click="handelLoginOut()">
        <img class="icon" src="@/assets/images/out.png" alt="" />
        <span>退出登录</span>
        <img class="right_icon" src="@/assets/images/right_icon.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { getLogOut } from "@/request/api";
export default {
  data() {
    return {
      loginUser: {},
    };
  },
  components: {},
  methods: {
    // 去登录
    handelGoLogin() {
      if (!this.loginUser) {
        this.$router.push("/Login");
      }
    },

    // 我的测评
    handelMyEvaluation() {
      this.$router.push("/myAssessment");
    },

    // 个人信息
    handelUserInfo() {
      this.$router.push("/userInfo");
    },

    // 退出登录
    handelLoginOut() {
      getLogOut().then((res) => {
        console.log(res);
        localStorage.removeItem("authorization");
        localStorage.removeItem("loginUser");
        this.$router.push("/Login");
      });
    },
  },
  created() {
    this.loginUser = JSON.parse(localStorage.getItem("loginUser"));
  },
};
</script>

<style scoped lang="less">
.my_info {
  width: 100%;
  min-height: calc(100vh - 0.88rem - 0.98rem);
  background: #f3f3f3;

  .header {
    width: 100%;
    height: 3.7rem;
    background-image: url("../../assets/images/my_bg.png");
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .header_img {
      width: 1.6rem;
      height: 1.6rem;
      margin-bottom: 0.26rem;
      text-align: center;
    }

    div {
      display: flex;
      align-items: center;
      flex-direction: column;

      h5 {
        font-size: 0.32rem;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        margin-bottom: 0.2rem;

        img {
          width: 0.3rem;
          height: 0.3rem;
          margin-left: 0.1rem;
        }
      }

      h6 {
        font-size: 0.28rem;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: #ffffff;
      }
    }
  }

  .menu {
    padding: 0 0.28rem;
    background-color: #ffffff;

    .item {
      display: flex;
      align-items: center;
      height: 1rem;
      font-size: 0.3rem;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: #333333;
      position: relative;

      .icon {
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.24rem;
      }

      .right_icon {
        width: 0.14rem;
        height: 0.29rem;
        position: absolute;
        top: 50%;
        right: 0.24rem;
        transform: translateY(-50%);
      }
    }

    .item:first-child {
      border-bottom: 0.01rem solid #eeeeee;
    }
  }

  .menu:last-child {
    margin-top: 0.2rem;
  }
}
</style>
